import React, { createContext, useContext, useState, useEffect } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const login = (userData) => {
    const expirationTime = Date.now() + 30 * 60 * 1000; // 30 minut w milisekundach
    const userDataWithExpiration = { ...userData, expirationTime };
    setUser(userDataWithExpiration);
    localStorage.setItem('user', JSON.stringify(userDataWithExpiration));
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem('user');
  };

  useEffect(() => {
    const savedUser = localStorage.getItem('user');

    if (savedUser) {
      const parsedUser = JSON.parse(savedUser);
      const currentTime = Date.now();

      if (parsedUser.expirationTime && parsedUser.expirationTime > currentTime) {

        setUser(parsedUser);
      } else {

        localStorage.removeItem('user');
        setUser(null);
      }
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = Date.now();
      if (user && user.expirationTime && user.expirationTime <= currentTime) {
        logout();
      }
    }, 60 * 1000);

    return () => clearInterval(interval); 
  }, [user]);

  return (
    <UserContext.Provider value={{ user, login, logout }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
