import React, { useState } from 'react';
import { Link, navigate } from 'gatsby';
import styled from 'styled-components';
import { Squeeze as Hamburger } from 'hamburger-react';
import { Logo, LoginIcon } from '../../assets/icons';
import hookrod_logo from '../../assets/images/LetsFishLogo.svg';
import { useUser } from '../../context/UserContext';
import { truncate } from '../../utils/truncate';

const Nav = () => {
  const [isToggled, setIsToggled] = useState(false);
  const { user, logout } = useUser();

  const handleLogout = () => {
    logout();
    navigate('/login'); // Redirect to login page
    setIsToggled(false); // Close menu after logout
  };

  const getRedirectPath = (roles) => {
    if (roles?.includes('ROLE_ADMIN')) {
      return '/panel-admin';
    } else if (roles?.includes('ROLE_CLIENT')) {
      return '/user-panel';
    } else if (roles?.includes('ROLE_OWNER')) {
      return '/owner-panel'
    } else {
      return '/registration';
    }
  };

  return (
    <NavCss>
      <div className="container">
        {/* Logo */}
        <Link to="/" className="nav_logo">
          <img src={hookrod_logo} alt="Hookrod Logo" />
        </Link>
        {/* Desktop Navbar */}
        <ul className="navbar_middle">
          <li>
            <Link to="/wybierz-województwo">Łowiska</Link>
          </li>
          <li>
            <Link to="/onas">O nas</Link>
          </li>
          <li>
            <Link to="/faq">FAQ</Link>
          </li>
        </ul>
        {/* Desktop Right Menu */}
        <ul className="navbar_right">
          <li>
            {user ? (
              <span onClick={handleLogout} className="logout">
                Wyloguj
              </span>
            ) : (
              <Link to="/login">Zaloguj</Link>
            )}
          </li>
          <li>
            <Link to={user ? getRedirectPath(user.roles) : '/registration'}>
              {user ? (
                <>
                  <LoginIcon /> {truncate(user.username, 8)}
                </>
              ) : (
                'Zarejestruj'
              )}
            </Link>
          </li>
        </ul>
        {/* Mobile Hamburger Menu */}
        <div className="hamburger">
          <Hamburger
            toggled={isToggled}
            toggle={setIsToggled}
            size={27}
            color="#fff"
            duration={0.6}
          />
        </div>
      </div>
      {/* Mobile Collapsible Menu */}
      {isToggled && (
        <div className="collapse_navbar">
          <Link to="/wybierz-województwo" onClick={() => setIsToggled(false)}>
            <h1>Łowiska</h1>
          </Link>
          <Link to="/onas" onClick={() => setIsToggled(false)}>
            <h1>O nas</h1>
          </Link>
          <Link to="/faq" onClick={() => setIsToggled(false)}>
            <h1>FAQ</h1>
          </Link>
          {user ? (
            <>
              <h1 onClick={handleLogout} className="logout">
                Wyloguj
              </h1>
              <Link to={getRedirectPath(user.roles)} onClick={() => setIsToggled(false)}>
                <h1>
                  <LoginIcon /> {truncate(user.username, 8)}
                </h1>
              </Link>
            </>
          ) : (
            <>
              <Link to="/login" onClick={() => setIsToggled(false)}>
                <h1>Zaloguj</h1>
              </Link>
              <Link to="/registration" onClick={() => setIsToggled(false)}>
                <h1>Zarejestruj</h1>
              </Link>
            </>
          )}
        </div>
      )}
    </NavCss>
  );
};

const NavCss = styled.nav`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 111;
  background: rgba(22, 56, 50, 0.9);
  transition: background 0.3s ease-out;

  .container {
    width: 90%;
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .nav_logo {
    width: 120px;
    img {
      width: 100%;
      height: auto;
    }
  }

  ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ul li {
    margin: 0 15px;
    font-size: 16px;
    color: var(--white);
    cursor: pointer;
  }

  ul li a {
    text-decoration: none;
    color: inherit;
  }

  .logout {
    cursor: pointer;
  }

  .hamburger {
    display: none;
  }

  @media screen and (max-width: 855px) {
    .navbar_middle li,
    .navbar_right li {
      display: none;
    }
    .hamburger {
      display: block;
    }
    .collapse_navbar {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: fixed;
      top: 50px;
      right: 0;
      width: 100%;
      background: rgba(22, 56, 50, 0.9);
      z-index: 110;
      padding: 10px 0;
    }
    .collapse_navbar h1 {
      font-size: 16px;
      margin: 10px 0;
      color: var(--white);
    }
  }
`;

export default Nav;
